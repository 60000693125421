<template>
  <div class="ddymh_homepage">
    <div class="topbanner_box">
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/banner1.png"
        class="bannerImg"
      />
    </div>
    <div class="system_lists">
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/htitle_2.png"
        class="img_title1"
      />
      <div class="system_item" v-for="(sitem,index) in systemList" :key="index">
        <img v-if="Math.floor(index/3)%2==0" :src="sitem.imgurl" class="sitem_img" />
        <p class="stxt">{{sitem.text}}</p>
        <img v-if="Math.floor(index/3)%2!=0" :src="sitem.imgurl" class="sitem_img" />
      </div>
    </div>
    <div class="gnbox">
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/htitle_1.png"
        class="img_title2"
      />
      <div class="wlists">
        <div class="witem" v-for="(gnitem,index) in gnList" :key="index">
          <img :src="gnitem.imgurl" class="wicon" />
          <p class="txt">{{gnitem.text}}</p>
        </div>
      </div>
    </div>
    <div class="hezuo_anli" @click="toHezuoAnli">
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/htitle_3.png"
        class="img_title3"
      />
      <p class="cont">截止2021年12月， 合作客户覆盖省份如下所示，业态覆盖：家政中介，母婴月嫂，保洁，产康，护工，家政培训，月子会所，物业保洁，养老等。</p>
      <div class="more_btn">
        <span>了解更多</span>
        <img src="../../../assets/images/more_white.png" alt />
      </div>
    </div>
    <div class="app_intro">
      <img
        class="phoneyanshi"
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/phoneyanshi.png"
        alt
      />
      <div class="ddyzs_intro">
        <img
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/logo.png"
          class="ddylogo"
        />
        <p class="name">咚咚燕助手</p>
        <p
          class="intro"
        >咚咚燕助手APP功能全面而易用，能够满足几乎所有家政公司的日常需求，提效率，降成本，就是这么简单。面向不同规模的家政公司，提供完整健全的SaaS家政管理工具，帮助家政企业降低运营成本促进快速发展。</p>
      </div>
    </div>
    <footers />
    <div class="fix_download" @click="toDownloadApp">下载咚咚燕APP</div>
    <!-- <a :href="appDownloadUrl" class="fix_download" target="_blank" @click="toDownloadApp">下载咚咚燕APP</a> -->
    <wxTipmask v-if="showWxTipMask" @closeMask="closeMask" />
  </div>
</template>  
<script>
import footers from "../components/footers.vue";
import wxTipmask from "../components/wxTipmask.vue";
export default {
  name: "mh_homepage",
  data() {
    return {
      systemList: [
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys1.png",
          text:
            "客户维护、阿姨匹配、在线合同签署，业务流程可视透明，提高家政公司工作效率。"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys2.png",
          text:
            "全面接入信用管理系统，整合权威第三方信用数据，快速查询家政员信用。"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys3.png",
          text:
            "课程推广奖励、培训课程奖励、保险奖励，帮助家政公司拓宽收益渠道。"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys4.png",
          text:
            "整合培训系统，将培训纳入业务管理，培训日常化，考试获证，上岗工作有条不紊。"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys5.png",
          text:
            "整合丰富的社交媒体营销工具，如微网站、海报、热文推广等功能，帮助家政公司0成本拉新获客。"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/sys6.png",
          text:
            "支持完整的电子合同签订、线上投保、三方面试等功能，加速商机落地。"
        }
      ],
      gnList: [
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_1.png",
          text: "员工管理"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_2.png",
          text: "店铺名片"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_3.png",
          text: "订单管理"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_4.png",
          text: "宣传海报"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_5.png",
          text: "微店铺"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_6.png",
          text: "热文推广"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_7.png",
          text: "商业保险"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_8.png",
          text: "客户管理"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_9.png",
          text: "电子网签"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_10.png",
          text: "招聘家政员"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_11.png",
          text: "政府平台对接"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_12.png",
          text: "上门证培训"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_13.png",
          text: "家政员管理"
        },
        {
          imgurl:
            "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/gn_14.png",
          text: "三方远程面试"
        }
      ],
      appDownloadUrl: "",
      showWxTipMask: false
    };
  },
  components: {
    footers,
    wxTipmask
  },
  mounted() {
    const appType = this.$publicFn.judgeType();
    console.log("#######################", appType);
    if (appType == "ios") {
      this.appDownloadUrl =
        "https://apps.apple.com/cn/app/%E5%92%9A%E5%92%9A%E7%87%95%E5%8A%A9%E6%89%8B/id1576662411";
    } else if (appType == "android") {
      this.appDownloadUrl =
        "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/android/app-evn_release-release.apk";
    }
  },
  methods: {
    toDownloadApp() {
      if (this.appDownloadUrl) {
        window.location.href=this.appDownloadUrl;
      }else if (/MicroMessenger/i.test(navigator.userAgent)) {
        this.showWxTipMask = true;
      }
    },
    closeMask() {
      this.showWxTipMask = false;
    },
    toHezuoAnli() {
      console.log("跳合作案例");
      this.$router.push("/cooperationCases"); //cms配置
    }
  }
};
</script>

<style lang="stylus" scoped>
.ddymh_homepage {
  background: #F9F9F9;
  // padding-bottom: 130px;
  padding-bottom: calc(50PX + 109px);

  .topbanner_box {
    width: 100%;
    font-size: 0;

    .bannerImg {
      width: 100%;
    }
  }

  .system_lists {
    padding-top: 24px;
    padding-bottom: 30px;
    background-color: #fff;

    .img_title1 {
      display: block;
      width: 712px;
      margin: 0 auto 48px;
    }

    .system_item {
      width: 670px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px auto 0;

      .sitem_img {
        width: 220px;
        height: 146px;
        flex-shrink: 0;
      }

      .stxt {
        width: 410px;
        font-size: 26px;
        color: #333333;
        line-height: 37px;
      }
    }
  }

  .gnbox {
    padding-top: 34px;
    padding-bottom: 20px;
    background-color: #F6FAFE;

    .img_title2 {
      display: block;
      width: 712px;
      margin: 0 auto 60px;
    }

    .wlists {
      display: flex;
      flex-wrap: wrap;

      .witem {
        width: 33.33%;
        text-align: center;
        margin-bottom: 50px;
        font-size: 0;

        .wicon {
          width: 72px;
          height: 72px;
        }

        .txt {
          font-size: 26px;
          color: #545472;
          line-height: 37px;
          margin-top: 11px;
        }
      }
    }
  }

  .hezuo_anli {
    padding-top: 34px;
    padding-bottom: 52px;
    background-color: #fff;

    .img_title3 {
      display: block;
      width: 712px;
      margin: 0 auto;
    }

    .cont {
      width: 630px;
      height: 190px;
      font-size: 26px;
      color: #333333;
      line-height: 44px;
      margin: 0 auto;
      word-break: break-all;
    }

    .more_btn {
      width: 280px;
      height: 80px;
      background: #0089FF;
      border-radius: 49px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 17px auto 0;

      span {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 37px;
        letter-spacing: 1px;
      }

      img {
        width: 13.2px;
        height: 24px;
        margin-left: 20px;
      }
    }
  }

  .app_intro {
    padding-top: 53px;
    padding-bottom: 30px;
    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/hphone_bg.png') no-repeat;
    background-size: 100% auto;

    .phoneyanshi {
      display: block;
      width: 718px;
      margin: 0 auto;
    }

    .ddyzs_intro {
      margin-top: 87px;
      text-align: center;
      font-size: 0;

      .ddylogo {
        width: 120px;
        height: 120px;
      }

      .name {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 45px;
        margin-top: 32px;
      }

      .intro {
        margin: 0 auto;
        margin-top: 38px;
        width: 638px;
        height: 237px;
        font-size: 26px;
        color: #333333;
        line-height: 40px;
        letter-spacing: 1px;
        text-align: left;
      }
    }
  }

  .fix_download {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50PX;
    z-index: 10;
    height: 110px;
    background: #0089FF;
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 1px;
    line-height: 110px;
    text-align: center;
  }
}
</style>