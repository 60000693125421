<template>
    <div class="ddymh_footer">
        <img
            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/jiazheng-h5/sdjz-h5/ddymh/logo_txt.png"
            class="f_logo"
        >
        <div class="f_info">
            <p>地址：北京市西城区北三环中路23号燕莎盛世大厦</p>
            <p>邮编：100000</p>
            <p>技术支持：伟东国信科技（北京）股份有限公司</p>
        </div>
        <div class="beianhao">
            <a
                class="bottom_text"
                href="https://beian.miit.gov.cn"
            >京ICP备10026535号-30</a>
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="stylus" scoped>
.ddymh_footer {
    padding-top: 30px;
    background: #00154E;

    .f_logo {
        display: block;
        width: 174px;
        height: 43px;
        margin: 0 auto 26px;
    }

    .f_info {
        padding: 25px 30px;
        font-size: 22px;
        color: #FFFFFF;
        line-height: 30px;
        border-top: 1px solid rgba(255, 255, 255 0.25);
        border-bottom: 1px solid rgba(255, 255, 255 0.25);
    }
    .beianhao{
        height:58px;
        display: flex;
        justify-content: center;
        align-items: center;
        .bottom_text {
            font-size: 18px;
            color: #A1A1A1;
            line-height: 30px;
            padding:0 10px;
        }
    }

    
}
</style>