<template>
    <div class="servicePay">
        <ul v-for="(item,index) in homemakingList" :key="index">
            <li>
                <div class="servicePay-left">家政机构</div>
                <div class="servicePay-right">{{item.companyName}}</div>
            </li>
            <li>
                <div class="servicePay-left">家政员</div>
                <div class="servicePay-right">{{item.homemakingName}}</div>
            </li>
            <li>
                <div class="servicePay-left">工作月份</div>
                <div class="servicePay-right">{{item.monthName}}</div>
            </li>
            <li>
                <div class="servicePay-left">工作天数</div>
                <div class="servicePay-right">{{item.workDay || 0}}天</div>
            </li>
            <li>
                <div class="servicePay-left">服务费</div>
                <div class="servicePay-right">{{item.receiveMoney || 0}}元</div>
            </li>
            <li v-if="isPay!==0">
                <div class="servicePay-left">实际支付</div>
                <div class="servicePay-right">{{item.receiveMoney || 0}}元</div>
            </li>
            <li v-if="isPay!==0">
                <div class="servicePay-left">支付时间</div>
                <div class="servicePay-right">{{item.payTime}}</div>
            </li>
        </ul>
        <div class="servicePay-tip" v-if="isPay==0">提示：{{isConfirm==1?'服务费信息已完成确认，请完成支付':'如果对服务费信息有疑问，请勿确认，请联系家政企业核对服务费信息'}}</div>
        <div class="servicePay-footer" v-if="isPay==0">
            <div class="servicePay-footer-left" v-if="isConfirm==1"><span>{{titleMoney}}</span>/元</div>
            <div class="servicePay-footer-right" @click="confirmBtn">{{isConfirm==1?'支付':'确认'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            getWx: "",
            titleMoney:0,
            isPay:0,
            isConfirm:0,
            detailObj:{},
            homemakingList:[],
            configObj:{},
            orderNo:null,
            uuid:null,
            userObj:null,
        };
    },
    created(){
        if(this.$route.query.uuid){
            this.uuid=this.$route.query.uuid;
            this.findByUuid();
            // this.getPayUserInfo();
        }
        if(this.$route.query.code){
            this.getAppId();
        }
        
    },
    methods: {
        // saveWxName(){
        //     let params={
        //         uuid:this.uuid,
        //         wxName:localStorage.getItem('nickname')
        //     }
        //     this.$api.servicePay.moneyRecordConfirm({params}).then((res) => {
        //         if (res.success) {
        //             console.log(res)
        //         }
        //     });
        // },
        confirmBtn(){
            if(this.isConfirm==1){
                if(localStorage.getItem('nickname') && localStorage.getItem('nickname')!=''){
                    this.getPay();
                }else{
                    this.getAppId();
                }
            }else{
               this.moneyRecordConfirm();
            }
        },
        async getPay(){
            let userObj=await this.getPayUserInfo();
            if(!userObj || userObj.success==false){
                return;
            }else{
                let params={
                    uuid:this.uuid,
                    wxName:localStorage.getItem('nickname'),
                }
                this.$api.servicePay.prePayOrder({params}).then(async(res) => {
                    if (res.message == "success") {
                        let url  = ''
                        let locationHref = window.location.href
                        if (locationHref.includes('testing')) {
                            url = 'pay.testing3.wdeduc.com'
                        } else if (locationHref.includes('fat')) {
                            url = 'pay.fat.wdeduc.com'
                        } else if (locationHref.includes('uat')) {
                            url = 'pay.uat.wdeduc.com'
                        } else  {
                            url = 'pay.wdeduc.com'
                        }
                        

                        // 跳转支付系统，在返回使用的标记
                        localStorage.setItem('hasJumpCashier', 1)
                        // const successBackUrl = window.location.protocol+'//'+window.location.hostname+'?isPay=true&uuid='+this.uuid

                        // const data = {
                        //     key: this.orderNo,
                        //     value: JSON.stringify({
                        //         orderNo: this.orderNo,
                        //         organ: this.userObj.organ,
                        //         token:this.userObj.token,
                        //         actor: this.userObj.actor,
                        //         branch: this.userObj.branch,
                        //         backUrl: window.location.origin,
                        //         successBackUrl: successBackUrl,
                        //         CSuccessBackUrl:successBackUrl,
                        //         type:'pay4'
                        //     })
                        // }
                        // let result = await this.$api.servicePay.jumpPaySaveParams(data)
                        window.location.href = "https://" + `${url}` + "/cashier?orderNo=" + `${this.orderNo}` + "&organ=" +userObj.data.organ +"&token=" +userObj.data.token +"&actor=" +userObj.data.actor + "&branch=" + userObj.data.branch
                    }
                });
            }
        },
        getAppId(){
            this.$api.servicePay.getAppId().then(res => {
                if(res.success){
                   if(this.$route.query.code){
                       this.$api.servicePay.getUserInfo(this.$route.query.code).then(res2 => {
                            if(res2.data){
                                localStorage.setItem('nickname',res2.data.nickname);
                                this.getPay();
                            }
                        })
                   }else{
                       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appId}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=statecs#wechat_redirect`
                   }
                }
            })
        },
        getPayUserInfo(){
            return this.$api.servicePay.getPayUserInfo({})
        },
        moneyRecordConfirm(){
            let params={
                uuid:this.uuid
            }
            this.$api.servicePay.moneyRecordConfirm({params}).then((res) => {
                if (res.success) {
                    document.title="服务费支付"
                    this.isConfirm=1;
                }else{
                    this.findByUuid();
                }
            });
        },
        findByUuid(){
            let params={
                uuid:this.uuid
            }
            this.$api.servicePay.findByUuid({params}).then((res) => {
                if (res.success) {
                    this.isPay=res.data.payStatus;
                    this.isConfirm=res.data.isConfirm;
                    this.homemakingList=res.data.homemakingList;
                    this.orderNo=res.data.orderNo;
                    this.titleMoney=0;
                    this.homemakingList.forEach((val)=>{
                        this.titleMoney=Number(this.titleMoney)+Number(val.receiveMoney);
                        val.monthName=val.month.slice(0,4)+'年'+val.month.slice(5,7)+'月'
                    })
                    if(this.isPay==0){
                        if(this.isConfirm==true){
                            document.title="服务费支付"
                        }else{
                            document.title="服务费确认"
                        }
                    }else{
                        document.title="支付信息"
                    }
                }
            });
        },
        WeiXin() {
            // 判断是否是微信内置浏览器
            let isWeiXin = () => {
                return (
                    navigator.userAgent
                        .toLowerCase()
                        .indexOf("micromessenger") !== -1
                );
            };
            if (isWeiXin()) {
                return true;
            } else {
                return false;
            }
        },
    },

    mounted(){
        this.getWx = this.WeiXin(); // 判断是否微信 true
    },
}

</script>
<style lang='stylus' scoped>
@import '../asset/css/servicePay.styl'
</style>