<template>
  <!-- 提示浏览器打开的弹框 -->
  <div class="full_mask" @click="closeMask" @touchmove.prevent>
    <div class="tips_cont">
      <div class="txt1 txt">
        1、点击右上角
        <img
          class="more_wx"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/icon/more_wx.png"
          alt
        />
        <img
          class="jt_wx"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/icon/jt_wx.png"
          alt
        />
      </div>
      <div class="txt txt2">
        2、选择在浏览器中打开
        <!-- <img
          class="liulanqi_wx"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/icon/liulanqi_wx.png"
          alt
        />  -->
      </div>
      <div class="txt">3、点击下载或安装即可</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods:{
    closeMask(){
      this.$emit("closeMask");
    }
  }
};
</script>

<style scoped lang="stylus">
.full_mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;

  .tips_cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8px;
    padding-right: 44px;
  }

  .txt {
    display: flex;
    align-items: center;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }

  .txt1 {
    align-items: flex-end;
  }

  .more_wx {
    width: 40px;
    height: 40px;
    margin: 0 8px;
  }

  .liulanqi_wx {
    width: 36px;
    height: 36px;
    margin: 0 8px;
  }

  .jt_wx {
    width: 102px;
    height: 124px;
    margin-bottom: 20px;
  }
}
</style>