<template>
  <div class="downloadApk">
    <div class="content">
      <img class="logo" src="../asset/images/logo.png" width="281" height="273" alt="">
      <div class="text version" v-show="version">
        咚咚燕助手v{{ version }}版本
      </div>
      <div class="text" v-show="updateTime">
        更新时间 {{ updateTime }}
      </div>
    </div>
    <div class="downloadBtn" @click="toDownloadApp">
      下载
    </div>
    <wxTipmask v-if="showWxTipMask" @closeMask="closeMask"/>
  </div>
</template>

<script>
import wxTipmask from "../components/wxTipmask.vue";

export default {
  components: {
    wxTipmask
  },
  data() {
    return {
      showWxTipMask: false,
      version: '',
      updateTime: '',
      appDownloadUrl: '',
    };
  },
  beforeCreate() {
    document.title = '';
  },
  created() {
    this.getApp();
  },
  methods: {
    toDownloadApp() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        this.showWxTipMask = true;
      } else if (this.appDownloadUrl) {
        window.location.href = this.appDownloadUrl;
      }
    },
    closeMask() {
      this.showWxTipMask = false;
    },
    getApp() {
      this.$api.downloadApk.getApp({
        platform: 3
      }).then(res => {
        if (res.success) {
          this.appDownloadUrl = res.data.url;
          this.version = res.data.outerVersion;
          this.updateTime = res.data.updateTime;
        }
      })
    },
  },
}
</script>
<style lang='stylus' scoped>
@import '../asset/css/downloadApk.css'
</style>